export const validationMixin = {
   methods: {
      errorMessage (fields) {

         for (const field of fields) {
            const type = field.$params.type;
            const param = field.$params;

            if (type) {
               switch (type) {
                  case "sameAs":
                     return "Este campo deve ser igual ao campo senha";
                  case "required":
                  case "requiredIf":
                     return "Este campo é obrigatório";
                  case "minLength":
                     return `Informe ao menos ${param.min} caracteres`;
                  case "maxLength":
                     return `Informe no máximo ${param.max} caracteres`;
                  case "length":
                     return `Informe ${param.size} caracteres`;
                  case "minValue":
                     return `O valor mínimo é ${param.min}`;
                  case "minValueNotIncluded":
                     return `O valor deve ser maior que ${param.min}`;
                  case "minDate":
                     return `O valor mínimo é ${this.$options.filters.moment(
                        param.min,
                        "DD/MM/YYYY"
                     )}`;
                  case "minCurrency":
                     return `O valor mínimo é R$ ${this.$options.filters.money(
                        param.min
                     )}`;
                  case "maxValue":
                     return `O valor máximo é ${param.max}`;
                  case "maxDate":
                     return `O valor mínimo é ${this.$options.filters.moment(
                        param.max,
                        "DD/MM/YYYY"
                     )}`;
                  case "maxCurrency":
                     return `O valor máximo é R$ ${this.$options.filters.money(
                        param.max
                     )}`;
                  case "date":
                     return "Informe uma data válida";
                  case "email":
                     return "Informe um e-mail válido";
                  case "cep":
                     return "Informe um CEP válido";
                  case "cnpj":
                     return "Informe um CNPJ válido";
                  case "phone":
                     return "Informe um telefone válido";
                  case "containsLetters":
                     return "Informe ao menos uma letra";
                  case "containsLettersLowercase":
                     return "Informe ao menos uma letra minúscula";
                  case "containsLettersUppercase":
                     return "Informe ao menos uma letra maiúscula";
                  case "containsNumbers":
                     return "Informe ao menos um número";
                  case "containsSpecialCharacters":
                     return "Informe ao menos um símbolo (#?!@$%&¨^~:=+-*/|_.,)";
                  default:
                     return "Confira o valor e tente novamente";
               }
            }
         }
      }
   }
};

export default validationMixin;
