import axiosClient from '@/axios'

const route = '/users';

const registerClient = async user => {
   return await axiosClient.post(`${route}/register-client`, user);
}

export default {
   registerClient
}
